import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeSequence } from 'dpl/shared/utils';
import { addToQuery, removeFromUrl } from 'dpl/util/queryString';

import { NextPageButton, PreviousPageButton } from './DirectionPageButton';

export default function Paginator({
  currentPage,
  itemsPerPage,
  onPageClick,
  totalItemCount
}) {
  const numberOfPages = Math.ceil(totalItemCount / itemsPerPage);

  if (numberOfPages < 2) {
    return null;
  }

  return (
    <div className="Paginator tc">
      <div className="inline-flex flex-wrap items-center">
        <PreviousPageButton
          disabled={currentPage === 1}
          onClick={onPageClick}
          pageNumber={currentPage - 1}
        />
        {window.__DPL_BOT_UA ? (
          makeSequence(numberOfPages).map(idx => {
            const pageNumber = idx + 1;
            const href =
              pageNumber > 1
                ? `?${addToQuery({ p: pageNumber }, window.location.search)}`
                : removeFromUrl('p', window.location.href);
            return (
              <a
                key={idx}
                href={href}
                className={classnames('mh2 link--primary', {
                  bb: currentPage === pageNumber
                })}
              >
                {pageNumber}
              </a>
            );
          })
        ) : (
          <span className="ph4">
            {currentPage} of {numberOfPages}
          </span>
        )}
        <NextPageButton
          disabled={currentPage === numberOfPages}
          onClick={onPageClick}
          pageNumber={currentPage + 1}
        />
      </div>
    </div>
  );
}

Paginator.propTypes = {
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  onPageClick: PropTypes.func.isRequired,
  totalItemCount: PropTypes.number
};

Paginator.defaultProps = {
  currentPage: 1,
  itemsPerPage: 10,
  totalItemCount: 0
};

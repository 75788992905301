import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { addToQuery } from 'dpl/util/queryString';
import Icon from 'dpl/common/components/Icon';

function DirectionPageButton({ direction, disabled, onClick, pageNumber }) {
  return (
    <a
      className={classnames(
        'DirectionPageButton br-100 ba bw1 b--near-white black tc flex items-center',
        {
          'o-0 pointer-events-none': disabled
        }
      )}
      aria-label={`${direction} page`}
      href={
        disabled
          ? '#'
          : `?${addToQuery({ p: pageNumber }, window.location.search)}`
      }
      onClick={e => {
        e.preventDefault();
        !disabled && onClick(pageNumber);
      }}
    >
      <Icon
        className="center"
        name={`fat-arrow-${direction === 'previous' ? 'left' : 'right'}`}
      />
    </a>
  );
}

DirectionPageButton.propTypes = {
  direction: PropTypes.oneOf(['next', 'previous']).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired
};

DirectionPageButton.defaultProps = {
  disabled: true
};

export function NextPageButton(props) {
  return <DirectionPageButton {...props} direction="next" />;
}

export function PreviousPageButton(props) {
  return <DirectionPageButton {...props} direction="previous" />;
}

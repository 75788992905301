import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { flatten } from 'dpl/shared/utils';
import { addToQuery, removeFromQuery } from 'dpl/util/queryString';
import { PHOTO_ALBUM_TYPE, IMAGE_ID } from 'dpl/constants/query_params';

export default function withPhotoAlbumCapabilities(photoAlbumType = '') {
  return WrappedComponent => {
    class PhotoAlbumComponent extends Component {
      static WrappedComponent = WrappedComponent;

      static displayName = `PhotoAlbumComponent(${WrappedComponent.name})`;

      static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        currentImageId: PropTypes.number,
        setImage: PropTypes.func.isRequired,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired
          })
        )
      };

      static defaultProps = {
        currentImageId: null,
        images: []
      };

      imagesById = {};
      imagesByCategory = {};
      imagesOrderedByCategory = [];

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillMount() {
        this.groupImagesById(this.props.images);
      }

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillReceiveProps(nextProps) {
        this.groupImagesById(nextProps.images);
        if (this.props.isOpen !== nextProps.isOpen) {
          nextProps.isOpen ? this.bindArrowKeys() : this.unbindArrowKeys();
        }
      }

      componentWillUnmount() {
        this.unbindArrowKeys();
      }

      bindArrowKeys() {
        window.addEventListener('keydown', this.handleKeyDown);
      }

      unbindArrowKeys() {
        window.removeEventListener('keydown', this.handleKeyDown);
      }

      handleKeyDown = e => {
        if (['ArrowRight', 'ArrowDown'].includes(e.key)) {
          e.preventDefault();
          this.props.setImage(this.nextImage.id);
        } else if (['ArrowLeft', 'ArrowUp'].includes(e.key)) {
          e.preventDefault();
          this.props.setImage(this.previousImage.id);
        }
      };

      get nextImage() {
        const { images } = this.props;
        return images[this.currentImage.arrayIdx + 1] || images[0];
      }

      get previousImage() {
        const { images } = this.props;
        return (
          images[this.currentImage.arrayIdx - 1] || images[images.length - 1]
        );
      }

      get currentImage() {
        const { currentImageId } = this.props;

        return this.imagesById[currentImageId]
          ? this.imagesById[currentImageId]
          : {};
      }

      get currentCategoryImages() {
        const { category } = this.currentImage;
        return this.imagesByCategory[category]
          ? this.imagesByCategory[category]
          : [];
      }

      getFirstImageForCategory = category => this.imagesByCategory[category][0];

      groupImagesById(images) {
        this.imagesById = {};
        this.imagesByCategory = {};
        this.imageCategories = [];
        const imagesWithoutCategory = [];

        images.forEach((image, idx) => {
          // mutating the original image so we can easily find it in the
          // original array (prev/next)
          image.arrayIdx = idx;

          this.imagesById[image.id] = image;

          if (image.category) {
            if (!this.imagesByCategory[image.category]) {
              this.imagesByCategory[image.category] = [];
              this.imageCategories.push(image.category);
            }
            this.imagesByCategory[image.category].push(image);
          } else {
            imagesWithoutCategory.push(image);
          }
        });

        this.imagesOrderedByCategory = flatten([
          Object.values(this.imagesByCategory),
          imagesWithoutCategory
        ]);
      }

      render() {
        if (!this.props.isOpen) {
          return null;
        }

        return (
          <WrappedComponent
            {...this.props}
            imagesById={this.imagesById}
            imagesByCategory={this.imagesByCategory}
            imageCategories={this.imageCategories}
            imagesOrderedByCategory={this.imagesOrderedByCategory}
            nextImage={this.nextImage}
            previousImage={this.previousImage}
            currentImage={this.currentImage}
            currentCategoryImages={this.currentCategoryImages}
            getFirstImageForCategory={this.getFirstImageForCategory}
          />
        );
      }
    }

    return connect(
      ({ queryParams }, { images }) => ({
        isOpen:
          photoAlbumType === queryParams[PHOTO_ALBUM_TYPE] && !!images.length,
        currentImageId: queryParams[IMAGE_ID]
          ? Number(queryParams[IMAGE_ID])
          : null
      }),
      {
        onClose: () =>
          replace(
            `#${removeFromQuery(
              [IMAGE_ID, PHOTO_ALBUM_TYPE],
              window.location.hash
            )}`
          ),
        setImage: id =>
          replace(
            `#${addToQuery(
              { [IMAGE_ID]: id, [PHOTO_ALBUM_TYPE]: photoAlbumType },
              window.location.hash
            )}`
          )
      }
    )(PhotoAlbumComponent);
  };
}

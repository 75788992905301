import isPreviewPage from 'dpl/shared/utils/isPreviewPage';

export function breederAppPaymentSettingsEditQueryDefinition(breederProfileId) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/payment_settings/edit.json`,
    defaultValue: {
      data: {
        breeder_profile: {
          billing_profile_attributes: {
            payout_methods_attributes: []
          }
        }
      }
    }
  };
}

export function breederAppBreederBreedIndexQueryDefinition(breederProfileId) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breeder_breeds`,
    defaultValue: {
      breeder_breeds: []
    }
  };
}

export function breederAppBreederBreedEditQueryDefinition(
  breederProfileId,
  breederBreedId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breeder_breeds/${breederBreedId}/edit.json`,
    defaultValue: {
      breeder_breeds: []
    }
  };
}

export function breederAppBreederBreedUpdateQueryDefinition(
  breederProfileId,
  breederBreedId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breeder_breeds/${breederBreedId}.json`,
    method: 'PUT'
  };
}

export function breederAppEditCustomQuestionsEditQueryDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/custom_application_questions/edit.json`,
    defaultValue: {
      breeder_profile: {
        custom_application_questions_attributes: []
      }
    }
  };
}

export function breederAppEditCustomQuestionsUpdateQueryDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/custom_application_questions.json`,
    method: 'PUT'
  };
}

export function breederProfileShowResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}`,
    params: {
      preview: isPreviewPage()
    }
  };
}

export function breederAppEditNotificationResourceDefinition(breederId) {
  return {
    url: `/api/breeder_profiles/${breederId}/breeder_notification_settings/edit.json`,
    defaultValue: {
      meta: {},
      breeder_notification_setting: {
        buyer_application_follow_up: false,
        community_updates: false,
        feedback: false,
        marketing: false
      }
    }
  };
}

export function breederAppUpdateNotificationResourceDefinition(breederId) {
  return {
    method: 'PUT',
    url: `/api/breeder_profiles/${breederId}/breeder_notification_settings.json`
  };
}

export function loggedOutEditNotificationResourceDefinition(breederId) {
  return {
    url: `/api/breeder_profiles/${breederId}/logged_out_notification_settings/edit.json`,
    defaultValue: {
      meta: {},
      breeder_notification_setting: {
        buyer_application_follow_up: false,
        community_updates: false,
        feedback: false,
        marketing: false
      }
    }
  };
}

export function loggedOutUpdateNotificationResourceDefinition(breederId) {
  return {
    method: 'PUT',
    url: `/api/breeder_profiles/${breederId}/logged_out_notification_settings.json`
  };
}

/**
 * @param {number | string} breederId
 * @param {{
 *   pageNumber: number | string; /* This is a user-readable number, not an index
 *   perPage: number | string;
 * }} options
 * @returns {ResourceDefinition}
 */
export function breederProfileBreederTestimonialIndexResourceDefinition(
  breederId,
  options = {}
) {
  const { pageNumber = 1, perPage = 10 } = options;

  return {
    url: `/api/breeder_profiles/${breederId}/testimonials.json`,
    params: {
      page: pageNumber,
      per_page: perPage
    }
  };
}

export function breederProfileBreederTestimonialShowResourceDefinition(
  breederId,
  testimonialId
) {
  return {
    url: `/api/breeder_profiles/${breederId}/testimonials/${testimonialId}.json`,
    params: { testimonial_id: testimonialId }
  };
}

export function breederAppSendBuyerApplicationRequestMutationDefinition(
  breederProfileId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/buyer_application_requests.json`,
    method: 'POST'
  };
}

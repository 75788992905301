/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import MediaCarousel from 'dpl/components/MediaCarousel';
import PhotoAlbumThumbnailButton from 'dpl/components/PhotoAlbum/PhotoAlbumThumbnailButton';
import {
  PHOTO_ALBUM_CATEGORIES_HEIGHT,
  PHOTO_ALBUM_THUMBS_WIDTH
} from 'dpl/constants/photo_album';
import { isMobileUA, noop } from 'dpl/shared/utils';
import { LitterPhotographPropTypes } from 'dpl/shared/utils/customPropTypes';

export default function PhotoAlbum({
  setImage,
  onClose,
  currentImage,
  imagesOrderedByCategory,
  imageCategories,
  getFirstImageForCategory,
  slim,
  renderPhotoDetails
}) {
  return (
    <MiniOverlayModal
      scrollableContent
      onCloseClick={onClose}
      maxWidth="1000px"
    >
      <div className="h-100 flex flex-column">
        <div
          className="overflow-hidden ph6 flex align-items-end mt4 mt0-md"
          style={{ flex: `0 0 ${PHOTO_ALBUM_CATEGORIES_HEIGHT}px` }}
        >
          <ul className="nowrap overflow-auto nh4">
            {imageCategories.length > 0 &&
              imageCategories.map(category => (
                <li
                  key={category}
                  className={classnames('transition dib bb bw2', {
                    'b--stone-700 stone-700':
                      currentImage.category === category,
                    'b--transparent mid-gray':
                      currentImage.category !== category
                  })}
                >
                  <a
                    className="pv2 ph3 db"
                    onClick={e => {
                      e.preventDefault();
                      setImage(getFirstImageForCategory(category).id);
                    }}
                    role="button"
                  >
                    {category}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="flex-auto h-100 flex">
          <div className="flex-auto relative">
            <div className="w-100 h-100 absolute bg-near-black">
              <MediaCarousel
                renderFileDetails={renderPhotoDetails}
                lazy={false}
                files={imagesOrderedByCategory}
                onFileChange={setImage}
                currentFile={currentImage}
                bulletsContainerClassName="d-md-none"
                smartImageProps={{
                  className:
                    'transform-center left-0 right-0 max-h-100 h-100 h-auto center',
                  imageOnlyClassName: 'max-h-100 h-auto',
                  loadingClass: 'h-100 loading-spinner',
                  alt: currentImage.caption || currentImage.alt_text
                }}
              />
            </div>
          </div>
          {!slim && (
            <ul
              style={{ flex: `0 0 ${PHOTO_ALBUM_THUMBS_WIDTH}px` }}
              className="overflow-y-auto"
            >
              {imagesOrderedByCategory.map(image => (
                <li className="f0" key={image.id}>
                  <PhotoAlbumThumbnailButton
                    onClick={() => setImage(image.id)}
                    isSelected={image === currentImage}
                    image={image}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </MiniOverlayModal>
  );
}

PhotoAlbum.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  imagesOrderedByCategory: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  currentImage: PropTypes.shape(LitterPhotographPropTypes).isRequired,
  imageCategories: PropTypes.arrayOf(PropTypes.string),
  getFirstImageForCategory: PropTypes.func,
  slim: PropTypes.bool,
  renderPhotoDetails: PropTypes.func
};

PhotoAlbum.defaultProps = {
  slim: isMobileUA(),
  renderPhotoDetails: noop,
  getFirstImageForCategory: noop,
  imageCategories: []
};

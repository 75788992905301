import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { pushParams } from 'dpl/actions/query_params';
import ImageCarouselImageVerifiedOwnerExpandedDetails from 'dpl/components/MediaCarousel/ImageCarouselImageVerifiedOwnerExpandedDetails';
import PhotoAlbum from 'dpl/components/PhotoAlbum';
import {
  BREEDER_PROFILE_ID,
  BREED_ID,
  IMAGE_ID,
  PHOTO_ALBUM_TYPE
} from 'dpl/constants/query_params';
import withPhotoAlbumCapabilities from 'dpl/decorators/withPhotoAlbumCapabilities';
import { PHOTO_ALBUM_TYPES } from 'dpl/shared/constants/breeder_app';
import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import {
  photographsResourceDefinition,
  singleBreederProfileResourceDefinition
} from 'dpl/shared/utils/tptConnectResourceDefinitions';

export const createBreederProfilePaginationAction = photoId =>
  pushParams(
    {
      [IMAGE_ID]: photoId,
      [PHOTO_ALBUM_TYPE]: PHOTO_ALBUM_TYPES.BREEDER
    },
    { hash: true }
  );

function BreederProfilePhotoAlbumContainer(props) {
  return (
    <PhotoAlbum
      {...{
        ...props,
        // eslint-disable-next-line react/display-name
        renderPhotoDetails: img =>
          img.details && (
            <div className="absolute bottom-0 white pt4 f2 w-100">
              <ImageCarouselImageVerifiedOwnerExpandedDetails
                details={img.details}
              />
            </div>
          )
      }}
    />
  );
}

export default compose(
  __DEPRECATED_defineResources(({ server, queryParams }) => {
    const breedId = queryParams[BREED_ID];
    const breederProfileId =
      server.breederProfileId || queryParams[BREEDER_PROFILE_ID];

    return {
      images: {
        ...photographsResourceDefinition(breederProfileId, {
          breed_id: breedId
        }),
        auto: Boolean(breederProfileId)
      },
      breederProfileData: {
        ...singleBreederProfileResourceDefinition(breederProfileId),
        auto: Boolean(breederProfileId)
      }
    };
  }),
  connect((state, props) => ({
    images: props.images.value.data
  })),
  withPhotoAlbumCapabilities(PHOTO_ALBUM_TYPES.BREEDER)
)(BreederProfilePhotoAlbumContainer);
